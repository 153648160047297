import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, SecondaryCallout, Image, RelatedContent } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Mechanism of Action of KEYTRUDA® (pembrolizumab) | Health Care Professionals`,
    keywords: `keytruda mechanism of action, pembrolizumab mechanism of action`,
    description: `KEYTRUDA® (pembrolizumab) binds to the PD-1 receptor and blocks its interaction with PD-L1 and PD-L2. Learn more here.`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/mechanism-of-action/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudahcp.com/resources/mechanism-of-action/","@type":"Substance","name":"KEYTRUDA®","alternateName":"pembrolizumab","description":"KEYTRUDA binds to the PD⁠-⁠1 receptor, blocking both immune-suppressing ligands, PD‑L1 and PD‑L2, from interacting with PD⁠-⁠1 to help restore T-cell response and immune response","activeIngredient":"pembrolizumab","recognizingAuthority":"Merck"}`
    ]
}

const jobCode = jobCodes[31].jobCode;

const relatedContent = [
    {
        section: 'Biomarker Testing',
        link: {
            url: '/biomarker-testing/pd-l1/',
            label: 'PD&#8288;-&#8288;L1 Testing & Scoring'
        }
    },
    {
        section: 'Safety',
        link: {
            url: '/safety/adverse-reactions/',
            label: 'Selected Adverse Reactions'
        }
    },
    {
        section: 'Dosing & Preparation',
        link: {
            url: '/dosing/options/',
            label: 'Dosing'
        }
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-c">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-c-intro">
                    <PageHeader title="Mechanism of Action" />
                    <PageSection bgColor="cloud" title="KEYTRUDA® (pembrolizumab): PD&#8288;-&#8288;1 Receptor Blockade">
                        <SecondaryCallout 
                            title="KEYTRUDA binds to the PD&#8288;-&#8288;1 receptor, blocking both immune-suppressing ligands, PD‑L1 and PD‑L2, from interacting with PD&#8288;-&#8288;1 to help restore T-cell response and immune response" 
                            alt=""    
                        />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}
                {/* Begin Main Column */}
                <TemplateColumn id="template-c-main">
                    <PageSection title="Restoring active T-cell response could affect both normal healthy cells and tumor cells">
                        <Image image={() => <StaticImage src="../../assets/hcp-opt-hcp-feature-1.png" placeholder="TRACED_SVG" alt="Mechanism of Action of KEYTRUDA® (pembrolizumab): Normal Immune Response" />} title="Normal immune response" caption="When functioning properly, T cells are activated and can attack tumor cells." />
                        <Image image={() => <StaticImage src="../../assets/hcp-opt-hcp-feature-2.png" placeholder="TRACED_SVG" alt="Mechanism of Action of KEYTRUDA® (pembrolizumab): Tumor Evasion and T-cell Deactivation" />} title="Tumor evasion and T-cell deactivation" caption="Some tumors can evade the immune system through the PD&#8288;-&#8288;1 pathway. The PD‑L1 and PD‑L2 ligands on tumors can bind with PD&#8288;-&#8288;1 receptors on T cells to inactivate the T cells." />
                        <Image image={() => <StaticImage src="../../assets/hcp-opt-hcp-feature-3.png" placeholder="TRACED_SVG" alt="Mechanism of Action of KEYTRUDA® (pembrolizumab): T-cell Reactivation" />} title="T-cell reactivation with KEYTRUDA" caption="KEYTRUDA binds to the PD&#8288;-&#8288;1 receptor and blocks its interaction with PD‑L1 and PD‑L2, which helps restore the immune response. While having an effect on the tumor, this could also affect normal healthy cells." definitions="PD&#8288;-&#8288;1 = programmed death receptor-1; PD&#8288;-&#8288;L1 = programmed death ligand 1; PD&#8288;-&#8288;L2 = programmed death ligand 2." />
                    </PageSection>
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                </TemplateColumn>
                {/* End Main Column */}
            </div>
        </DefaultLayout>
    );
};

export default Page;
